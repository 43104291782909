export const getDefaultState = () => {
  const defaultState = {
    compsType: 'company',
    issueType: '144A',
    classificationSelected: 'Industry Group (L2)',
    outliersIncluded: false,
    initialising: false,
    hasPersistedState: false,
    originalColumnSet: [],
    customColumnSet: [],
    filterItemMap: [],
    sortInfo: {},
    fxSetting: {
      company: 'RAW',
      sector: 'USD',
    },
  }
  return defaultState
}
export default getDefaultState()
