import { getDefaultState } from './state'
export const SET_ISSUE_TYPE = 'SET - Market issue type'
export const SET_CLASSIFICATION = 'SET - Classification view'
export const SET_INITIALISING = 'COMPARABLES - Set Initialising'
export const SET_OUTLIERS_INCLUDED = 'COMPARABLES - Set Outliers Included'
export const SET_HAS_PERSISTED_STATE = 'COMPARABLES - Set Has Persisted State'
export const SET_CUSTOM_COLUMN_SET = 'COMPARABLES - Set Custom Column Set'
export const SET_ORIGINAL_COLUMN_SET = 'COMPARABLES - Set Original Column Set'
export const SET_FX_SETTING = 'COMPARABLES - Set FX Setting'
export const SET_COMPS_TYPE = 'COMPARABLES - Set Comparables Type'
export const SET_FILTER_ITEM_MAP = 'COMPARABLES - Set Filter Item Map'
export const SET_SORT_INFO = 'COMPARABLES - Set Sort Info'
export const RESET_COMPS = 'COMPARABLES - Reset Comparables'
export const CLEAR_COMPS = 'COMPARABLES - Clear Comparables'

export default {
  [SET_ISSUE_TYPE](state, payload) {
    state.issueType = payload
  },
  [SET_CLASSIFICATION](state, payload) {
    state.classificationSelected = payload
  },
  [SET_INITIALISING](state, payload) {
    state.initialising = payload
  },
  [SET_OUTLIERS_INCLUDED](state, payload) {
    state.outliersIncluded = payload
  },
  [SET_HAS_PERSISTED_STATE](state, payload) {
    state.hasPersistedState = payload
  },
  [SET_CUSTOM_COLUMN_SET](state, payload) {
    state.customColumnSet = payload
  },
  [SET_ORIGINAL_COLUMN_SET](state, payload) {
    state.originalColumnSet = payload
  },
  [SET_FX_SETTING](state, payload) {
    state.fxSetting[state.compsType] = payload
  },
  [SET_COMPS_TYPE](state, payload) {
    state.compsType = payload
  },
  [SET_FILTER_ITEM_MAP](state, payload) {
    state.filterItemMap = payload
  },
  [SET_SORT_INFO](state, payload) {
    state.sortInfo = payload
  },
  [RESET_COMPS](state, payload) {
    const defaultState = getDefaultState()
    if (payload === 'all') {
      const compsType = state.compsType
      const originalColumnSet = state.originalColumnSet
      Object.assign(
        state,
        { ...defaultState },
        {
          compsType,
          originalColumnSet,
          customColumnSet: originalColumnSet,
        },
      )
    } else {
      if (payload.includes('issueType')) {
        state.issueType = defaultState.issueType
      }
      if (payload.includes('gics')) {
        state.classificationSelected = defaultState.classificationSelected
      }
      if (payload.includes('outliers')) {
        state.outliersIncluded = defaultState.outliersIncluded
      }
      if (payload.includes('fxSetting')) {
        state.fxSetting = defaultState.fxSetting
      }
      if (payload.includes('columns')) {
        state.customColumnSet = [...state.originalColumnSet]
      }
      if (payload.includes('filters')) {
        state.filterItemMap = defaultState.filterItemMap
        state.sortInfo = defaultState.sortInfo
      }
    }
  },
  [CLEAR_COMPS](state) {
    const defaultState = getDefaultState()
    Object.assign(state, { ...defaultState })
  },
}
